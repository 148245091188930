export const formatDeliveryDate = (
  isoDate: string,
  startTime: string,
  endTime: string
): string => {
  const deliveryDate = new Date(isoDate);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Check if the delivery date is today
  if (
    deliveryDate.getFullYear() === today.getFullYear() &&
    deliveryDate.getMonth() === today.getMonth() &&
    deliveryDate.getDate() === today.getDate()
  ) {
    return `Next available delivery is today from ${startTime} to ${endTime}`;
  }

  // Check if the delivery date is tomorrow
  else if (
    deliveryDate.getFullYear() === tomorrow.getFullYear() &&
    deliveryDate.getMonth() === tomorrow.getMonth() &&
    deliveryDate.getDate() === tomorrow.getDate()
  ) {
    return `Next available delivery is tomorrow from ${startTime} to ${endTime}`;
  }

  // Format for other dates
  else {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
    };
    const formattedDate = deliveryDate.toLocaleDateString("en-US", options);
    return `Delivery ${formattedDate} from ${startTime} to ${endTime}`;
  }
};
