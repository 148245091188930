import { parseISO, isToday, isTomorrow, addDays } from "date-fns";

export const isDelayed = (deliveryDate: string): boolean => {
  const parsedDate = parseISO(deliveryDate);
  const today = new Date();

  // Check if the delivery date is today or tomorrow
  if (isToday(parsedDate) || isTomorrow(parsedDate)) {
    return false; // Not delayed
  }

  // Function to get the next available day for delivery
  const getNextAvailableDay = (date: Date): Date => {
    const day = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    // If today is Friday (5) or Saturday (6), add days to jump to Monday (1)
    if (day === 5) {
      return addDays(date, 3); // Jump to Monday
    } else if (day === 6) {
      return addDays(date, 2); // Jump to Monday
    }

    return date; // Return the date unchanged if it's not Friday or Saturday
  };

  // Get the next available delivery day
  const nextAvailableDay = getNextAvailableDay(today);

  // Calculate the difference in days
  const daysUntilDelivery = Math.ceil(
    (parsedDate.getTime() - nextAvailableDay.getTime()) / (1000 * 60 * 60 * 24)
  );

  // Check if the delivery date is more than 1 day away
  return daysUntilDelivery > 1;
};

// Example usage
// const deliveryDate: string = "2024-09-29T00:00:00.000Z";
// const delayed: boolean = isDelayed(deliveryDate);
