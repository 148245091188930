/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

const GET_ITEMS = gql`
  query ($collectionId: ID!, $take: Int!, $skip: Int!) {
    search(input: { collectionId: $collectionId, take: $take, skip: $skip }) {
      items {
        productName
        productId
        productVariantCount
        slug
        currencyCode
        merchantAsset {
          id
          preview
        }
        priceWithTax {
          ... on PriceRange {
            min
            max
          }

          ... on SinglePrice {
            value
          }
        }
        description
        productAsset {
          preview
        }
      }
    }
  }
`;

const GET_COLLECTIONS = gql`
  query Getcollections {
    collections {
      items {
        id
        name
        slug
        parent {
          slug
        }
        featuredAsset {
          id
          width
          height
          name
          preview
        }
      }
    }
  }
`;

const GET_COLLECTION = gql`
  query GetCollection($slug: String) {
    collection(slug: $slug) {
      id
      name
      description
      children {
        id
        slug
        name
        children {
          id
          slug
          name
        }
      }
    }
  }
`;

const featuredAsset = `featuredAsset {
  id
  createdAt
  updatedAt
  name
  type
  fileSize
  mimeType
  width
  height
  source
  preview
  focalPoint {
    x
    y
  }
}`;

const assets = `assets {
  id
    createdAt
    updatedAt
    name
    fileSize
    type
    mimeType
    width
    height
    focalPoint {
      x
      y
    }
    preview
    source
}`;

const translations = `translations {
    id
    createdAt
    updatedAt
    languageCode
    name
}`;

const facetValues = `facetValues {
  id
  name
  facet {
    id
    name
    code
  }
}`;

const GET_PRODUCT = gql`
  query ($slug: String!) {
    product(slug: $slug) {
      id
      createdAt
      updatedAt
      languageCode
      name
      slug
      description
      ${featuredAsset}
      ${assets}
      variants {
        id
        productId
        createdAt
        updatedAt
        languageCode
        sku
        name
        ${featuredAsset}
        ${assets}
        price
        currencyCode
        priceWithTax
        stockLevel
        taxCategory {
          id
          name
          isDefault
        }
        options {
          id
          createdAt
          updatedAt
          languageCode
          code
          name
          groupId
          group {
            id
            createdAt
            updatedAt
            languageCode
            code
            name
            options {
              id
            }
          }
          ${translations}
        }
        ${translations}
        ${facetValues}
      }
      optionGroups {
        id
        code
        name
      }
      ${facetValues}
      ${translations}
      collections {
        id
        name
        slug
        children {
          id
          createdAt
          updatedAt
          languageCode
          name
          slug
          productVariants {
            items {
              id
              product {
                id
              }
              productId
            }
            totalItems
          }
        }
        position
      }
      merchant {
        id
        name
        description
        featuredAsset {
          id
          name
          type
          fileSize
          preview
          source
        }
      }
    }
  }
`;

const GET_FILTERS_PARAMS = gql`
  query {
    search(input: { take: 1000, skip: 0 }) {
      totalItems

      facetValues {
        count
        facetValue {
          id
          name
          facet {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
    }
  }
`;

const GET_ITEMS_WITH_FILTERS = gql`
  query (
    $term: String
    $facetValueIds: [ID!]
    $collectionId: ID
    $take: Int
    $skip: Int
    $sort: SearchResultSortParameter
    $merchantSlug: String
    $groupByProduct: Boolean
  ) {
    search(
      input: {
        term: $term
        facetValueIds: $facetValueIds
        collectionId: $collectionId
        take: $take
        skip: $skip
        sort: $sort
        merchantSlug: $merchantSlug
        groupByProduct: $groupByProduct
      }
    ) {
      facetValues {
        count
        facetValue {
          id
          createdAt
          updatedAt
          languageCode
          facet {
            id
            createdAt
            updatedAt
            languageCode
            name
            code
            translations {
              id
              languageCode
              name
              __typename
            }
            __typename
          }
          name
          __typename
        }
        __typename
      }
      items {
        sku
        slug
        productId
        productName
        merchantSlug
        merchantName
        merchantDescription
        productVariantCount
        deliveryDate
        merchantAsset {
          id
          preview
        }
        productAsset {
          id
          preview
          focalPoint {
            x
            y
            __typename
          }
          __typename
        }
        price {
          ... on PriceRange {
            min
            max
          }

          ... on SinglePrice {
            value
          }
        }
        priceWithTax {
          ... on PriceRange {
            min
            max
          }

          ... on SinglePrice {
            value
          }
        }
        productVariantId
        productVariantName
        productVariantAsset {
          id
          preview
          focalPoint {
            x
            y
            __typename
          }
          __typename
        }
        currencyCode
        description
        facetIds
        facetValueIds
        collectionIds
        score
        __typename
      }
    }
  }
`;

const GET_MERCHANT_LOGOS = gql`
  query {
    search(input: {}) {
      items {
        productId
        productName
        merchantSlug
        merchantAsset {
          id
          preview
        }
      }
    }
  }
`;

export {
  GET_ITEMS,
  GET_COLLECTIONS,
  GET_PRODUCT,
  GET_FILTERS_PARAMS,
  GET_ITEMS_WITH_FILTERS,
  GET_COLLECTION,
  GET_MERCHANT_LOGOS,
};
