import { Backdrop, Fade, makeStyles, Modal } from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import {
  h4Medium,
  h5Medium,
  h6Medium,
  pointer,
} from "../../../shared/constants";
import CheckoutTotalItem from "../checkout-total-item/checkout-total-item.component";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ACTIVE_ORDER } from "../../../queries/cart.queries.js";
import Loader from "../../common/loader/loader.component";
import { MOVE_STATE } from "../../../queries/checkout.queries";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import { CartContext } from "../../../context/cartContext";
import { useState } from "react";
import OrderRecieved from "../order-recieved/order-recieved.component";

const useStyles = makeStyles({
  totalContainer: {
    display: "flex",
    flexDirection: "column",
  },

  container: {
    maxHeight: 400,
    padding: 40,
    borderRadius: 16,
    background: "#FFFFFF",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  edit: {
    ...h6Medium,
    ...pointer,
    color: "#80BB34",
  },

  title: {
    ...h4Medium,
    opacity: 0.8,
  },

  itemsContainer: {
    borderTop: "1px dashed #15222F1A",
    marginTop: 32,
    height: 200,
    overflowX: "hidden",
    overflowY: "auto",
  },

  total: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 16,
    ...h6Medium,
    opacity: 0.8,
  },

  button: {
    height: 56,
    width: "100%",
    border: "none",
    textAlign: "center",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
    background: "#80BB34",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const CheckoutTotal = ({ processes }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { cartState, setCartState } = useContext(CartContext);
  const { isCartOpen } = cartState;

  // const [open, setOpen] = useState(false);
  const [urlRedirect, setUrlRedirect] = useState("");
  const [open, setOpen] = useState(false);

  const { loading: orderLoading, data: activeOrder } =
    useQuery(GET_ACTIVE_ORDER);

  // const [addPayment, { data }] = useMutation(ADD_PAYMENT, {
  //   variables: {
  //     metadata: {},
  //     method: "standard-payment",
  //   },
  //   refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
  // });

  const [moveState, { loading: moveStateLoading }] = useMutation(MOVE_STATE, {
    variables: {
      // state: "ArrangingPayment",
      state: "Open",
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }],
    onCompleted: (data) => {
      if (!data?.transitionOrderToState) return;
      setOpen(true);
    },
  });

  React.useEffect(() => {
    if (!urlRedirect || urlRedirect === undefined) return;
    window.location = urlRedirect;
  }, [urlRedirect]);

  const { stage4Payment } = processes;

  useEffect(() => {
    if (!activeOrder?.activeOrder) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [moveStateToAddingItems, { loading: moveStateToAddingItemsLoader }] =
    useMutation(MOVE_STATE, {
      variables: {
        state: "AddingItems",
      },
      refetchQueries: [{ query: GET_ACTIVE_USER }],
      onCompleted: async () => toggleCart(),
    });

  if (orderLoading) {
    return <Loader color={"#B73232"} size={70} />;
  }

  const onConfirmOrder = async () => {
    if (!activeOrder?.activeOrder) return;
    try {
      // const { data } = await moveState();
      await moveState();
    } catch (e) {
      alert(e?.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.location = "/catalog";
  };

  const onEditOrder = async () => {
    try {
      await moveStateToAddingItems();
    } catch (err) {
      alert(err.message);
    }
  };

  const toggleCart = () => {
    setCartState({
      ...cartState,
      isCartOpen: !isCartOpen,
    });
  };

  const orderHasContent = activeOrder?.activeOrder?.lines?.length > 0;

  return (
    <React.Fragment>
      <div className={classes.totalContainer}>
        <div className={classes.container}>
          <div className={classes.header}>
            <span className={classes.title}>{t("checkout.Order")}</span>
            <span
              className={classes.edit}
              onClick={() => {
                onEditOrder();
              }}
            >
              {moveStateToAddingItemsLoader ? (
                <Loader color={"#B73232"} size={30} />
              ) : (
                t("checkout.Edit")
              )}
            </span>
          </div>

          <div className={classes.itemsContainer}>
            {activeOrder?.activeOrder?.lines?.map((item) => (
              <CheckoutTotalItem item={item} key={item?.id} />
            ))}
          </div>

          <div
            className={classes.total}
            style={{
              color: "#15222F",
              opacity: 0.64,
            }}
          >
            <span>{t("account.delivery")} </span>
            <span>
              ₪
              {isNaN(activeOrder?.activeOrder?.shippingWithTax / 100)
                ? 0
                : (activeOrder?.activeOrder?.shippingWithTax / 100).toFixed(2)}
            </span>
          </div>

          <div className={classes.total}>
            <span>{t("checkout.Total")} </span>
            {activeOrder?.activeOrder?.totalWithTax ? (
              <span>
                ₪{(activeOrder?.activeOrder?.totalWithTax / 100).toFixed(2)}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <button
          className={classes.button}
          disabled={!stage4Payment}
          style={{
            background:
              stage4Payment && orderHasContent ? "#80BB34" : "#15222F",
            cursor:
              stage4Payment && orderHasContent ? "pointer" : "not-allowed",
            opacity: stage4Payment && orderHasContent ? 1 : 0.16,
          }}
          onClick={onConfirmOrder}
        >
          <span>
            {moveStateLoading ? (
              <Loader color={"#B73232"} size={30} />
            ) : (
              t("checkout.confirmOrder")
            )}
          </span>
        </button>
      </div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <OrderRecieved handleClose={handleClose} />
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default CheckoutTotal;
